<template>
    <section class="bg-login-cargando">
        <div class="row justify-content-center w-100">
            <div class="col-10 col-sm-8 col-md-7 col-lg-5 col-xl-5 text-center">
                <img src="/img/generales/logoClaroMCD.svg" alt="" class="img-logo heartbeat" />
            </div>
        </div>
    </section>
</template>
<script>
export default {
    layout: 'seleccionarConjunto',
    data(){
        return{


        }
    },
    created(){
        if (this.$usuario.tipo == 4){ // Usuario tipo vigilante
            setTimeout( () => this.$router.push({ name: "zonas.historial"}), 2000);
        }else if (this.$usuario.tipo == 6){ // Usuario tipo contador
            setTimeout( () => this.$router.push({ name: "dashboard.main"}), 2000);
        }else{
            setTimeout( () => this.$router.push({ name: "dashboard.main"}), 2000);
        }
    },
    methods:{

    }

}
</script>
<style lang="scss" scoped>
.bg-login-cargando{
    background: transparent linear-gradient(180deg, #717171 0%, #000 100%) 0% 0% no-repeat padding-box;
    background-size: cover;
    background-position-x: right;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    .img-logo{
        width: 183px;
        height: 176px;
    }
    .input-codigo{
        width: calc((100% / 4) - 8px);
        .el-input__inner{
            text-align: center !important;
        }
    }
    .heartbeat{-webkit-animation:heartbeat 2s ease-in-out 5 both;animation:heartbeat 2s ease-in-out 5 both}
    /* ----------------------------------------------
    * Generated by Animista on 2021-10-6 14:24:55
    * Licensed under FreeBSD License.
    * See http://animista.net/license for more info. 
    * w: http://animista.net, t: @cssanimista
    * ---------------------------------------------- */

    @-webkit-keyframes heartbeat{from{-webkit-transform:scale(1);transform:scale(1);-webkit-transform-origin:center center;transform-origin:center center;-webkit-animation-timing-function:ease-out;animation-timing-function:ease-out}10%{-webkit-transform:scale(.91);transform:scale(.91);-webkit-animation-timing-function:ease-in;animation-timing-function:ease-in}17%{-webkit-transform:scale(.98);transform:scale(.98);-webkit-animation-timing-function:ease-out;animation-timing-function:ease-out}33%{-webkit-transform:scale(.87);transform:scale(.87);-webkit-animation-timing-function:ease-in;animation-timing-function:ease-in}45%{-webkit-transform:scale(1);transform:scale(1);-webkit-animation-timing-function:ease-out;animation-timing-function:ease-out}}@keyframes heartbeat{from{-webkit-transform:scale(1);transform:scale(1);-webkit-transform-origin:center center;transform-origin:center center;-webkit-animation-timing-function:ease-out;animation-timing-function:ease-out}10%{-webkit-transform:scale(.91);transform:scale(.91);-webkit-animation-timing-function:ease-in;animation-timing-function:ease-in}17%{-webkit-transform:scale(.98);transform:scale(.98);-webkit-animation-timing-function:ease-out;animation-timing-function:ease-out}33%{-webkit-transform:scale(.87);transform:scale(.87);-webkit-animation-timing-function:ease-in;animation-timing-function:ease-in}45%{-webkit-transform:scale(1);transform:scale(1);-webkit-animation-timing-function:ease-out;animation-timing-function:ease-out}}
}
</style>